
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '../../../lib/util'
    import AbpBase from '../../../lib/abpbase'
    import AirlineCode from '../../../store/entities/airlinecode'

    @Component
    export default class AirlineCodeForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({type: Boolean, default: false}) public toEdit: boolean;
        airlineCode: AirlineCode = new AirlineCode();
        private countryList: any;

        created() {
        }

        data() {
            return {
                countryList: []
            }
        };

        async mounted() {
            this.countryList = await this.$store.dispatch('country/lookUp');
        }

        save() {
            (this.$refs.airlineCodeForm as any).validate(async (valid: boolean) => {
                if (valid) {
                    await this.$store.dispatch({
                        type: this.toEdit ? 'airlinecode/update' : 'airlinecode/create',
                        data: this.airlineCode
                    });
                    (this.$refs.airlineCodeForm as any).resetFields();
                    this.$emit('save-success');
                    this.$emit('input', false);
                }
            })
        }

        cancel() {
            (this.$refs.airlineCodeForm as any).resetFields();
            this.$emit('input', false);
        }

        visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                if (this.toEdit) {
                    this.airlineCode = Util.extend(true, {}, this.$store.state.airlinecode.editAirlineCode);
                } else {
                    this.airlineCode = new AirlineCode();
                }
            }
        }

        checkCountryInput(rule, value, callback) {
            if (value == null) {
                return callback(new Error(this.L('FieldIsRequired', undefined, this.L('Country'))));
            }
            return callback();
        }

        subdivisionTypeRule = {
            name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}],
            iataCode: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Code')), trigger: 'blur'}],
            countryId: [{
                required: true,
                validator: this.checkCountryInput,
                trigger: 'change'
            }]
        }
    }
