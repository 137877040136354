

    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import AirlineCodeFrom from './airlinecode-form.vue'
    import SortingModel from "@/store/entities/sortingmodel";

    class PageAirlineCodeRequest extends PageRequest {
        countryId: number | null;
    }

    @Component({
        components: {AirlineCodeFrom}
    })

    export default class AirlineCode extends AbpBase {
        private countryList: any;

        edit() {
            this.editModalShow = true;
        }

        async changeSort(data) {
            if (this.pagerequest.sorting == null) {
                this.pagerequest.sorting = [];
            }
            let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
            if (item == null) {
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pagerequest.sorting.push(sortingModel)
            } else {
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getpage()
        }

        pagerequest: PageAirlineCodeRequest = new PageAirlineCodeRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;

        data() {
            return {
                countryList: [],
                updateCount: 0,
            }
        };

        async handleUpload(file) {
            let formData = new FormData();
            formData.append('file', file);
            await this.$store.dispatch({
                type: 'airlinecode/uploadFile',
                data: formData
            })
            await this.getpage();
        }

        async mounted() {
            this.countryList = await this.$store.dispatch('country/lookUp');
        }

        get list() {
            return this.$store.state.airlinecode.list;
        };

        get loading() {
            return this.$store.state.airlinecode.loading;
        }

        create() {
            this.createModalShow = true;
        }

        isCountryChange(val: number) {
            if (val === 0)
                this.pagerequest.countryId = null;
            else
                this.pagerequest.countryId = val;
        }

        pageChange(page: number) {
            this.$store.commit('airlinecode/setCurrentPage', page);
            this.getpage();
        }

        pagesizeChange(pagesize: number) {
            this.$store.commit('airlinecode/setPageSize', pagesize);
            this.getpage();
        }

        async search() {
            this.$store.commit('airlinecode/setCurrentPage', 1);
            await this.getpage();
        }

        async getpage() {

            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'airlinecode/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.airlinecode.pageSize;
        }

        get totalCount() {
            return this.$store.state.airlinecode.totalCount;
        }

        get currentPage() {
            return this.$store.state.airlinecode.currentPage;
        }

        columns = [
            {
                title: this.L('Name'),
                key: 'name',
                sortable: 'custom'
            }, {
                title: this.L('IATADesignator'),
                key: 'iataCode',
                sortable: 'custom'
            }, {
                title: this.L('Code'),
                key: 'code',
                sortable: 'custom'
            }, {
                title: this.L('ICAODesignator'),
                key: 'icaoCode',
                sortable: 'custom'
            }, {
                title: this.L('Country'),
                key: 'countryName',
                sortable: 'custom'
            }, {
                title: this.L('Actions'),
                key: 'Actions',
                width: 150,
                render: (h: any, params: any) => {
                    return h('div', [
                        h('Button', {
                            props: {
                                type: 'primary',
                                size: 'small',
                                disabled: !this.hasEditPermission()
                            },
                            style: {
                                marginRight: '5px'
                            },
                            on: {
                                click: async () => {
                                    this.$store.commit('airlinecode/edit', await this.$store.dispatch({
                                        type: 'airlinecode/get',
                                        data: params.row.id
                                    }));
                                    this.edit();
                                }
                            }
                        }, this.L('Edit')),
                        h('Button', {
                            props: {
                                type: 'error',
                                size: 'small',
                                disabled: !this.hasDeletePermission()
                            },
                            on: {
                                click: async () => {
                                    this.$Modal.confirm({
                                        title: this.L('Tips'),
                                        content: this.L('Delete airline code confirm'),
                                        okText: this.L('Yes'),
                                        cancelText: this.L('No'),
                                        onOk: async () => {
                                            await this.$store.dispatch({
                                                type: 'airlinecode/delete',
                                                data: params.row
                                            })
                                            await this.getpage();
                                        }
                                    })
                                }
                            }
                        }, this.L('Delete'))
                    ])
                }
            }]

        async created() {
            await this.getpage();
        }

        hasAddPermission() {
            return this.hasPermission("Pages.AirlineCodes.Add");
        }

        hasEditPermission() {
            return this.hasPermission("Pages.AirlineCodes.Edit");
        }

        hasDeletePermission() {
            return this.hasPermission("Pages.AirlineCodes.Delete");
        }

    }
